import React from "react"
import Layout from "../components/Layout"

const about = () => {
  return (
    <Layout>
      <div className="container">
        <p>Hello guys! I am SR</p>

        <p>
          Welcome to <b>Coding Gyan</b>, your number one source for all things
          related to <b>Web design</b>. We're dedicated to giving you the very
          best of Web designs with a focus on quality and real-world problem
          solution.
        </p>
        <p>
          Founded in 2020-11-29 by SR, Coding Gyan has come a long way from its
          beginnings in <b>Maharastra</b> located in{" "}
          <b>India 🇮🇳, Republic of India</b>. When SR first started out, our
          passion for Web design drove us to start our own blog/website.
        </p>
        <p>
          We hope you enjoy our blog as much as We enjoy offering them to you.
          If you have any questions or comments, please don't hesitate to
          contact us.
        </p>
        <p>Sincerely, SR</p>
      </div>
    </Layout>
  )
}

export default about
